import { ApiRequest } from 'src/api';
import { KEY } from '../../utils/configs/app';
import { PATH } from '../Path';
import { REQUEST_METHOD } from 'src/utils/constants/enums';
import { ICommonCallBackApi } from 'src/interfaces/common/common';
import { authHelper } from 'src/utils/helpers';
import { RESPONSE_ERROR, RESPONSE_SUCCESS } from 'src/utils/constants/http';

const getCurrentUser: any = async () => {
  const userInStore = localStorage.getItem(KEY.keyAuthLocal);
  const parseUser = userInStore ? JSON.parse(userInStore) : { user: null, jwt: '' };
  if (!userInStore || !parseUser?.user) {
    return {
      status: 403,
      isError: true,
    };
  }

  const { isError, data, status, message } = await ApiRequest(`${PATH.USER}/${parseUser.user.id}`, REQUEST_METHOD.GET, true);
  if (isError) {
    return {
      status,
      isError,
    };
  }
  return {
    jwt: parseUser.jwt,
    user: {
      blocked: data?.blocked,
      created_at: data?.createdAt,
      email: data?.email,
      id: data?.id,
      firstname: data?.firstname,
      lastname: data?.lastname,
      role_id: data?.role?.id,
      role_name: data?.role?.name,
      type: data?.role?.type,
      username: data?.createdAt,
    },
  };
};

const logOut = () => {
  let res = {} as ICommonCallBackApi;
  localStorage.clear();

  return (res = {
    status: RESPONSE_SUCCESS,
    data: null,
  });
};
const authService = {
  getCurrentUser,
  logOut,
};

export default authService;
