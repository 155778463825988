import LogoFooter from '../../assets/images/logo-footer.svg';
import '../../styles/pages/footer.scss';
import { DATE_PATTERN } from 'src/utils/constants/enums';
import moment from 'moment';
const Footer = () => {
  return (
    <>
      <div className="components__footer">
        <img src={LogoFooter} alt="Hitachi" className="components__footer-img-logo" />
        <div className="components__footer-info">
          <span color="#414141" data-cy="components__footer-copyright" className="components__footer-copyright">
            © Hitachi Corporation {moment(Date.now()).format(DATE_PATTERN.YYYY)}. All Rights Reserved.
          </span>
          <span>
            <a href="/" className="components__footer-license">
              License information
            </a>
          </span>
        </div>
      </div>
      <div className="components__footer-opacity"></div>
    </>
  );
};

export default Footer;
