export const KEY = {
  keyAuthLocal: 'userInfo',
  keyTokenLocal: 'token',
  loginRemember: 'loginRemember',
  BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:1337/api',
  MAP: process.env.REACT_APP_MAP_API_KEY ? process.env.REACT_APP_MAP_API_KEY : '',
};
export const KEY_CUS = {
  keyAuthLocal: 'userInfo',
  BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:1337/api',
  DOWNLOAD_URL: process.env.REACT_APP_DOWNLOAD_URL ? process.env.REACT_APP_DOWNLOAD_URL : 'http://localhost:1337/api',
};
export const LINK_EDIT_PROFILE = {
  url: process.env.REACT_APP_PROFILE_EDIT_URL ? process.env.REACT_APP_PROFILE_EDIT_URL : 'https://myaccount.microsoft.com/',
};
