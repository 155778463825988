import { useEffect, useState } from 'react';

function useHandleZoom() {
  const [fontSize, setFontSize] = useState(14);
  const [zoomRatio, setZoomRatio] = useState(1);

  useEffect(() => {
    const updateZoomRatio = () => {
      setZoomRatio(window.devicePixelRatio);
    };
    window.addEventListener('resize', updateZoomRatio);
    updateZoomRatio();
    return () => {
      window.removeEventListener('resize', updateZoomRatio);
    };
  }, []);

  useEffect(() => {
    if (zoomRatio >= 1.75) {
      setFontSize(9);
    } else if (zoomRatio >= 1.5) {
      setFontSize(11);
    } else if (zoomRatio < 1.5 && zoomRatio > 0.5) {
      setFontSize(14);
    } else {
      setFontSize(16);
    }
  }, [zoomRatio]);

  return {
    fontSize,
  };
}

export default useHandleZoom;
