import { createContext, useMemo, useState } from 'react';

const initDataProjects: GeneralInfoCreateProject = {
  project_name: '',
  sales_force_id: '',
  project_starting_year: undefined,
  project_duration: undefined,
  customer_requirements: '',
  eco_business_unit: undefined,
  eco_business_line: undefined,
  eco_global_product_group: undefined,
  files: [],
  eco_sub_projects: {
    subProject_name: '',
    country: undefined,
    city: undefined,
    region: undefined,
    region_id: undefined,
    purpose_of_study: undefined,
    files: [],
    customer_requirements: '',
  },
  project_responsible: '',
  eco_project_code: {
    id: 0,
    project_code: '',
  },
  isPrevStep: false,
};

interface IStoreGlobalContext {
  isLoading: boolean;
  setIsLoading: any;
  infoSubProject: any;
  setInfoSubProject: any;
  openEditProject: boolean;
  setOpenEditProject: any;
  dataProject: GeneralInfoCreateProject;
  setDataProject: (project: GeneralInfoCreateProject) => void;
  resetDataProject: () => void;
}

const StoreGlobalContext = createContext<IStoreGlobalContext>({
  isLoading: false,
  setIsLoading: null,
  openEditProject: false,
  infoSubProject: null,
  setInfoSubProject: null,
  dataProject: initDataProjects,
  setOpenEditProject: null,
  setDataProject: () => {},
  resetDataProject: () => {},
});

function GlobalStateProvider(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [openEditProject, setOpenEditProject] = useState(false);
  const [infoSubProject, setInfoSubProject] = useState();
  const [infoProject, setInfoProject] = useState<GeneralInfoCreateProject>(initDataProjects);
  const storeGlobal = useMemo(
    () => ({
      isLoading: isLoading,
      setIsLoading,
      openEditProject: openEditProject,
      setOpenEditProject,
      infoSubProject: infoSubProject,
      setInfoSubProject,
      dataProject: infoProject,
      setDataProject: setInfoProject,
      resetDataProject: () => setInfoProject(initDataProjects),
    }),
    [isLoading, setIsLoading, openEditProject, setOpenEditProject, infoSubProject, setInfoSubProject, infoProject, setInfoProject],
  );

  return <StoreGlobalContext.Provider value={storeGlobal}>{props.children}</StoreGlobalContext.Provider>;
}
export { StoreGlobalContext, GlobalStateProvider, initDataProjects };
