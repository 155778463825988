import { useEffect, useState } from 'react';

// Custom hook for handling errors globally
export function useGlobalErrorHandler() {
  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      console.error('Error: ', event.error);
    };

    const handlePromiseRejection = (event: PromiseRejectionEvent) => {
      console.error('Promise Error: ', event.reason);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handlePromiseRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
    };
  }, []);
}
